<template>
  <div class="mainBox">
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="this.type=='edit'?$t('cip.plat.sys.user.title.editHeadTitle'):$t('cip.plat.sys.user.title.addHeadTitle')"
                 @head-save="headSave('save')" @head-cancel="headCancel" @head-save-back="headSave('back')">
    </head-layout>
    <div class="tabsBox">
      <el-tabs v-model="activeName">
        <el-tab-pane label="用户信息" name="user">
          <el-form :model="dataForm" :rules="rules" ref="ruleForm" label-width="100px">
            <el-collapse v-model="activeNames" style="height: calc(100vh - 240px);overflow: auto">
              <el-collapse-item title="基础信息" name="1">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="用户编号:" prop="code">
                      <el-input v-model="dataForm.code" placeholder="请输入用户编号"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="用户姓名:" prop="realName">
                      <el-input v-model="dataForm.realName" placeholder="请输入用户姓名"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="登录账号:" prop="account">
                      <el-input v-model="dataForm.account" placeholder="请输入登录账号"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="证件号码:" prop="identityCard">
                      <el-input v-model="dataForm.identityCard" placeholder="请输入证件号码"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="出生日期:" prop="birthday">
                      <el-date-picker type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="dataForm.birthday" placeholder="请选择出生日期"></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="用户性别:" prop="sex">
                      <el-select v-model="dataForm.sex" placeholder="请选择用户性别">
                        <el-option
                          v-for="item in sexOptions"
                          :key="item.dictKey"
                          :label="item.dictValue"
                          :value="Number(item.dictKey)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="手机号码:" prop="phone">
                      <el-input v-model="dataForm.phone" placeholder="请输入手机号码"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="是否启用:" prop="isEnabled">
                      <el-select v-model="dataForm.isEnabled" placeholder="请选择是否启用">
                        <el-option
                          v-for="item in isEnabledOptions"
                          :key="item.dictKey"
                          :label="item.dictValue"
                          :value="Number(item.dictKey)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="" prop="files">
                      <label slot="label">
                        <el-tooltip class="item" effect="dark"  placement="top-start">
                          <template slot="content" >
                            <p>①五官无遮挡：如刘海、长发，勿遮挡眉毛、脸颊。不可出现曝光不均匀（阴阳脸）</p>
                            <p>②正脸大头照：不能用精修的证件照</p>
                            <p>③背景干净：不可出现其他人脸，过度曝光的背景环境</p>
                            <p>④表情正常</p>
                            <p>⑤照片比例为4:3，最佳分辨率为600*600以上</p>
                            图片支持*.jpg/*.png格式,文件大小不超过5M
                          </template>
                          <i class="el-tooltip el-icon-info item"></i>
                        </el-tooltip>
                        人员近照:
                      </label>
                      <el-upload
                          action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                          :headers="headers"
                          list-type="picture-card"
                          :on-success="uploadSuccess"
                          :before-upload="checkFileType"
                          accept=".jpg,.png"
                          :file-list="fileList"
                          :on-remove="onRemove"
                          :on-preview="()=>{imgVisible = true}"
                      >
                        <i class="el-icon-plus"></i>

                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item title="档案信息" name="2">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="所在地:" prop="personnelLocation">
                      <el-radio-group v-model="dataForm.personnelLocation">
                        <el-radio :label="Number(item.dictKey)" v-for="(item,index) in locationOptions"
                                  :key="item.dictKey">{{ item.dictValue }}
                        </el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="国籍:" prop="nationality">
                      <el-select v-model="dataForm.nationality" filterable placeholder="请选择国籍">
                        <el-option
                            v-for="item in countryOptions"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="人员类型:" prop="personnelType">
                      <el-select v-model="dataForm.personnelType" placeholder="请选择人员类型">
                        <el-option
                            v-for="item in personnelTypeOptions"
                            :key="item.dictKey"
                            :label="item.dictValue"
                            :value="Number(item.dictKey)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item title="所属部门" name="3">
                <el-row>
                  <el-col :span="24">
                    <el-form-item prop="dept" label-width="0">
                      <el-tag v-for="(item,index) in dataForm.roleIds" :key="item.id" style="margin-right: 12px">
                        {{ item.deptName }}
                      </el-tag>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="组织部门" name="dept">
          <head-layout :head-btn-options="deptHeadOptions"
                       @head-add="addDept">
          </head-layout>
          <el-table
              :data="dataForm.roleIds"
              border
              stripe
              size="medium"
              height="calc(100vh - 288px)"
              style="width: 100%">
            <el-table-column
                label="序号"
                type="index"
                align="center"
                width="50">
            </el-table-column>
            <el-table-column
                prop="deptName"
                label="组织名称"
                width="200">
            </el-table-column>
            <el-table-column
                prop="isDefaultDept"
                label="是否主组织"
                align="center"
                width="150">
              <template slot-scope="scope">
                <el-switch
                    @change="chooseDefault(scope)"
                    v-model="scope.row.isDefaultDept"
                    active-text="是"
                    inactive-text="否"
                    active-value="1"
                    inactive-value="0">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
                prop="isDisplay"
                label="是否默认部门"
                align="center"
                width="150">
              <template slot-scope="scope">
                <el-switch
                    @change="chooseDisplay(scope)"
                    v-model="scope.row.isDisplay"
                    active-text="是"
                    inactive-text="否"
                    active-value="1"
                    inactive-value="0">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
                label="角色"
                header-align="center"
            >
              <template slot-scope="scope">
                <el-checkbox style="margin: 15px" v-model="scope.row['roleCheck' + index]"
                             v-for="(item,index) in userListData" :key="index"
                             @change="checkData(scope,item)">{{ item.roleName }}
                </el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="操作"
                width="70">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="deleteRoleId(scope)"><span
                    style="color: #F56C6C">删除</span>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
        title="组织部门选择"
        :visible.sync="dialogVisible"
        width="800px">
      <div>
        <avue-input-tree
            v-if="dialogVisible"
            ref="deptSelect"
            v-model="deptIds"
            multiple
            checkStrictly
            :props="deptProps"
            :clearable="false"
            placeholder="请选择组织"
            :dic="deptOptions">
        </avue-input-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitDept">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="imgVisible" append-to-body title="人员近照">
      <div style="text-align: center">
        <el-image v-for="item in fileList" :src="item.url" :key="item.url"></el-image>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout"
import {add, getUser, update, selectByLevel, roleGetList} from "@/api/system/user";
import {dictionaryBiz} from "@/api/reportTasks";
import {getDic} from "@/api/dict"
import {getDept, getDeptTree} from "@/api/system/dept";
import {getToken} from "@/util/auth";
import Template from "@/views/message/template/list.vue";

export default {
  components: {Template, HeadLayout},
  data() {
    return {
      type: '',
      id: '',
      dataForm: {
        code: '',
        account: '',
        realName: '',
        phone: '',
        tenantId: '000000',
        roleIds: [],
        sex: '',
        isEnabled: 0,
        nationality: '5651',
        personnelType: 1,
        personnelLocation: 1,
        birthday:''
      },
      rules: {
        code: [
          {required: true, message: '请输入用户编号', trigger: 'blur'},
          {
            pattern: /[a-zA-Z0-9]$/,
            message: this.$t("cip.plat.sys.user.msg.onlyEnglish"),
          }
        ],
        account: [
          {required: true, message: '请输入登录账号', trigger: 'blur'},
          {
            pattern: /[a-zA-Z0-9]$/,
            message: this.$t("cip.plat.sys.user.msg.onlyEnglish"),
          },
        ],
        phone: [
          {
            required: true,
            // pattern: /^1[0-9]{10}$/,
            message: this.$t("cip.plat.sys.user.field.contactNumber"),
            trigger: "blur"
          }
        ],
        "realName": [
          {required: true, message: '请输入用户姓名', trigger: 'blur'}
        ],
        isEnabled: [
          {required: true, message: '请选择是否启用', trigger: 'change'}
        ],
        personnelLocation: [
          {required: true, message: '请选择所在地', trigger: 'change'}
        ],
        nationality: [
          {required: true, message: '请选择国籍', trigger: 'change'}
        ],
        personnelType: [
          {required: true, message: '请选择人员类型', trigger: 'change'}
        ],
      },
      activeName: 'user',
      activeNames: ['1', '2', '3'],
      sexOptions: [],
      isEnabledOptions: [],
      locationOptions: [],
      countryOptions: [],
      personnelTypeOptions: [],
      deptOptions: [],
      deptProps: {
        label: "title",
        value: "value"
      },
      headBtnOptions: [
        {
          label: this.$t('cip.cmn.btn.saveBtn'),
          emit: "head-save",
        },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
        }
      ],
      deptHeadOptions: [
        {
          label: "配置部门",
          emit: "head-add",
        },
      ],
      dialogVisible: false,
      deptIds: [],
      userListData: [],
      tableData: [],
      fileList: [],
      imgVisible: false,
    }
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    this.id = id;
    this.getDictory();
    if (this.type == 'add' && this.$route.query.row) {
      this.addInitData()
    }
  },
  methods: {
    // 获取数据字典
    getDictory() {
      // 性别
      getDic("gender").then((res) => {
        this.sexOptions = res.data.data;
      });
      // 是否
      dictionaryBiz("ISENBLE").then((res) => {
        this.isEnabledOptions = res.data.data;
      });
      // 所在地
      dictionaryBiz("domestic_overseas").then((res) => {
        this.locationOptions = res.data.data;
      });
      // 国籍
      selectByLevel({regionLevel: 1}).then((res) => {
        this.countryOptions = res.data.data;
      })
      // 人员类型
      getDic("personnel_type").then((res) => {
        this.personnelTypeOptions = res.data.data;
      });
      // 组织数据
      getDeptTree("000000", '2').then((res) => {
        this.deptOptions = res.data.data;
      });
      // 角色数据
      roleGetList(1, 500).then((res) => {
        this.userListData = res.data.data.records;
        if (this.type == 'edit') {
          this.getUserDetail();
        }
      })
    },
    // 添加人员跳转后接收数据
    addInitData() {
      let row = JSON.parse(decodeURIComponent(this.$route.query.row))
      console.log("401-row:",row)
      this.dataForm.account = row.account
      this.dataForm.code = row.code
      this.dataForm.sort = row.rank
      this.dataForm.isEnabled = row.status
      this.dataForm.realName = row.realName
      this.dataForm.phone = row.phone
      this.dataForm.email = row.email
      this.dataForm.sex = row.sex ? Number(row.sex) : null
      this.dataForm.identityCard = row.idNum
      this.dataForm.birthday = row.birthday ? row.birthday + ' 00:00:00' : ''
    },
    // 只有一个主组织
    chooseDefault(scope) {
      this.dataForm.roleIds.forEach((item, index) => {
        if (scope.$index != index && item.isDefaultDept == '1') {
          item.isDefaultDept = '0'
        }
      })
    },
    // 只有一个默认部门
    chooseDisplay(scope) {
      this.dataForm.roleIds.forEach((item, index) => {
        if (scope.$index != index && item.isDisplay == '1') {
          item.isDisplay = '0'
        }
      })
    },
    // 打开选择组织弹窗
    addDept() {
      let ids = new Array();
      this.dataForm.roleIds.forEach((item) => {
        ids.push(item.deptId)
      })
      this.deptIds = ids;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.deptSelect.$children[0].toggleMenu();
      })
    },
    // 确定选择组织
    submitDept() {
      let dataList = new Array();
      if (this.dataForm.roleIds.length != 0) {
        // 新增
        this.deptIds.forEach((item) => {
          this.dataForm.roleIds.forEach((items) => {
            if (item == items.deptId) {
              dataList.push(items);
            }
          })
        })
        let dataListRoleIds = new Array();
        dataList.forEach((item) => {
          dataListRoleIds.push(item.deptId)
        })
        let filteredArray = [...new Set([...this.deptIds, ...dataListRoleIds])]
            .filter(item => !this.deptIds.includes(item) || !dataListRoleIds.includes(item));
        filteredArray.forEach((item) => {
          getDept(item).then((res) => {
            dataList.push({
              deptName: JSON.parse(res.data.data.deptName).zh,
              deptId: res.data.data.id,
              roleId: [],
              isDefaultDept: 0
            })
          })
        })
      } else {
        // 编辑
        this.deptIds.forEach((item) => {
          getDept(item).then((res) => {
            dataList.push({
              deptName: JSON.parse(res.data.data.deptName).zh,
              deptId: res.data.data.id,
              roleId: [],
              isDefaultDept: 0
            })
          })
        })
      }
      this.dataForm.roleIds = dataList;
      this.dialogVisible = false;
    },
    // 选择角色
    checkData(scope) {
      let indexList = []
      Object.keys(scope.row).forEach(key => {
        if (scope.row[key] === true) {
          indexList.push(key.substring(9))
        }
      });
      scope.row.roleId = [];
      scope.row.roleName = [];
      for (let i = 0; i < this.userListData.length; i++) {
        indexList.forEach(e => {
          if (i.toString() === e) {
            scope.row.roleId.push(this.userListData[i].id)
            scope.row.roleName.push(this.userListData[i].roleName)
          }
        })
      }
    },
    // 删除部门
    deleteRoleId(scope) {
      this.dataForm.roleIds.splice(scope.$index, 1)
    },
    // 获取用户信息
    getUserDetail() {
      getUser(this.id).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          console.log("512",data)
          // 回显树选择
          data.deptId = data.deptId.split(',');
          // 回显表格勾选角色
          this.$nextTick(() => {
            data.roleIds.forEach((e) => {
              if (e.roleId.includes(',')) {
                e.roleId = e.roleId.split(',');
              } else {
                e.roleId = [e.roleId];
              }
              this.userListData.forEach((item, index) => {
                let checked = 'roleCheck' + index;
                this.$set(e, checked, false)
                e.roleId.forEach((roleId) => {
                  if (roleId == item.id) {
                    this.$set(e, checked, true)
                  }
                })
              })
            })
          })
          console.log("1231231",data);
          this.dataForm = data;
          this.fileList = data.files ? JSON.parse(data.files) : []
        }
      })
    },
    // 保存
    headSave(type) {
      this.$refs.ruleForm.validate((valid) => {

        if (valid) {
          let requestType = this.dataForm.hasOwnProperty('id') ? update : add;
          let dataForm = JSON.parse(JSON.stringify(this.dataForm));
          if (dataForm.roleIds.length == 0) {
            this.$message({
              type: "warning",
              message: "请确保用户至少有一个部门"
            });
            return;
          }

          if (dataForm.deptId) {
            dataForm.deptId = dataForm.deptId.join(',');
          }

          // 判断是否勾选主组织
          let isMainDept = dataForm.roleIds.filter((item) => {
            if (item.isDefaultDept == 1) {
              return item;
            }
          })
          if (isMainDept.length == 0) {
            this.$message({
              type: "warning",
              message: "请勾选其中一个部门为主组织"
            });
            return;
          }
          // 判断是否勾选默认部门
          let isMainDisplay = dataForm.roleIds.filter((item) => {
            if (item.isDisplay == 1) {
              return item;
            }
          })
          if (isMainDisplay.length == 0) {
            this.$message({
              type: "warning",
              message: "请勾选其中一个部门为默认部门"
            });
            return;
          }
          // 判断是否选择了角色
          let isEmptyRole = dataForm.roleIds.filter((item) => {
            if (item.roleId.length == 0) {
              return item;
            }
          })
          if (isEmptyRole.length != 0) {
            this.$message({
              type: "warning",
              message: "请确保每个部门中至少勾选一个角色"
            });
            return;
          }

          dataForm.roleIds.forEach((item) => {
            item.roleId = item.roleId.join(',');
            if (Array.isArray(item.roleName)) {
              item.roleName = item.roleName.join(',');
            }
          })
          requestType(dataForm).then((res) => {
            let data = res.data.data;
            this.id = data.id;
            this.dataForm.id = data.id;
            this.type = 'edit';
            this.getUserDetail();
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess")
            });
            if (type == 'back') {
              this.headCancel();
            }
          })
        } else {
          this.$message({
            type: "warning",
            message: "请完善用户信息表单必填项"
          });
        }
      });
    },
    // 返回
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

    // 上传
    checkFileType(file) {
      const fileType = file.name.substring(file.name.lastIndexOf("."));
      const isLt20M = file.size / 1024 / 1024 < 5;
      const isJPG = fileType === ".jpg" || fileType == ".png";
      if (!isJPG) {
        this.$message.warning("上传图片只能是 .jpg  .png格式!");
      }
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 5MB!");
      }
      return isJPG && isLt20M;
    },
    uploadSuccess(res, file, fileList) {
      // this.form.imgUrl = URL.createObjectURL(file.raw);
      let data = {...res.data}
      this.fileList = [{
        ...data,
        label: '人员证照',
        value: data.link,
        url: data.link,
        thumbnailLink: data.link,
        uid: new Date().getTime(),
        name: file.name,
      }]
      this.dataForm.files = JSON.stringify(this.fileList)
    },
    onRemove(file) {
      this.fileList = []
      this.dataForm.files = ''
    },
  },
  computed: {
    headers: function () {
      return {"Sinoma-Auth": getToken()};
    },
  }
}
</script>
<style lang="scss" scoped>
.mainBox {
  width: 100%;
  height: 100%;
}

.tabsBox {
  background-color: #FFFFFF;
  padding: 10px 12px;
  width: calc(100% - 24px);
  height: calc(100% - 65px);
}

::v-deep .el-tabs__content {
  background-color: transparent;
}

::v-deep .el-upload--picture-card,
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 200px;
  height: 150px;
}
</style>
